@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
}

#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Serpentine';
}

button {
  background-color: transparent;
  border: none;
  padding: none;
  outline: none;
  cursor: pointer;
}

body {
  background: #f0f0f0;
}

.Configurator {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden !important;
}

.Configurator canvas {
  width: 100%;
  height: 100%;
}

.loading-asset {
  width: auto;
  position: fixed;
  width: 100%;
  height: var(--vh);
  backdrop-filter: blur(20px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20;
  transition: all 300ms ease-in-out;
}

.loading-asset img {
  width: 20%;
  height: auto;
  object-fit: contain;
}

.loading-overlay {
  width: auto;
  position: absolute;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(20px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  transition: all 300ms ease-in-out;
}

.loading-overlay.screen {
  z-index: 20;
}

.loading-overlay.show {
  z-index: 10;
  opacity: 1;
  visibility: visible;
}

.loading-overlay.show + #three-canvas {
  opacity: 0.4;
}

.loading-overlay img {
  width: 20%;
  height: auto;
  object-fit: contain;
}

.bg-btn {
  position: relative;
}

.bg-btn.show::before {
  opacity: 1;
}

.bg-btn::before {
  opacity: 0;
  content: "";
  position: absolute;
  top: calc(2.5rem / 2);
  left: 0;
  width: 2.5rem;
  height: 2px;
  background-color: #535760;
  transform: rotate(-45deg);
}

.rangeslider-vertical {
  margin: 10px 0 !important;
  height: 100% !important;
  width: 5px !important;
}
.rangeslider-vertical .rangeslider__fill {
  background-color: #535760 !important;
}

.rangeslider-vertical .rangeslider__handle {
  border: 2px solid #535760 !important;
  width: 28px !important;
  height: 28px !important;
  left: -11px !important;
  border-radius: 50% !important;
}

.rangeslider-vertical .rangeslider__labels {
  visibility: hidden;
}

@font-face {
  font-family: 'Serpentine';
  src: url("./assets/fonts/SerpentineW01Regular.ttf");
  font-weight: 400;
}

@media (min-width: 1280px) {
  .bg-btn::before {
    width: 4rem;
    top: calc(4rem / 2);
  }
}

@keyframes flickerAnimation {
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
@-o-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
@-moz-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
@-webkit-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
.animate-flicker {
  -webkit-animation: flickerAnimation 1.5s infinite;
  -moz-animation: flickerAnimation 1.5s infinite;
  -o-animation: flickerAnimation 1.5s infinite;
  animation: flickerAnimation 1.5s infinite;
}

.chrome-picker {
  border-radius: 12px !important;
}

.chrome-picker > div:first-child {
  border-radius: 12px 12px 0 0 !important;
}

.chrome-picker div .flexbox-fix:nth-child(1) > div:nth-child(1) div {
  margin-top: 0 !important;
  width: 20px !important;
  height: 20px !important;
  border-radius: 50% !important;
}

.chrome-picker div .flexbox-fix:nth-child(2) > .flexbox-fix > div:nth-child(1) div {
  width: 100% !important;
  height: auto !important;
}


.chrome-picker div .flexbox-fix:nth-child(1) {
  column-gap: 10px !important;
}

.chrome-picker div + div > .flexbox-fix:nth-child(1) > div:nth-child(2) div {
  margin-bottom: 0 !important;
}

.chrome-picker div + div .flexbox-fix:nth-child(1) > div:nth-child(2) .hue-horizontal div:nth-child(2) {
  display: block !important;
  visibility: visible !important;
}

.chrome-picker div + div .flexbox-fix:nth-child(1) div:nth-child(2) {
  align-self: center !important;
}

.chrome-picker div + div .flexbox-fix:nth-child(1) > div:nth-child(2) div:nth-child(2) {
  display: none !important;
  visibility: hidden !important;
}

.chrome-picker div + div .flexbox-fix:nth-child(2) div:nth-child(2) {
  display: none !important;
  visibility: hidden !important;
}

.chrome-picker div + div .flexbox-fix:nth-child(2) div:nth-child(1) input {
  font-family: 'Serpentine';
  color: #535760 !important;
}

.chrome-picker div + div .flexbox-fix:nth-child(2) div:nth-child(1) label {
  display: none !important;
}